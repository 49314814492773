html{
  overflow: hidden;
  overflow-y: scroll;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-body{
  min-height: 200px;
  max-height: 550px;
  width: 500px;
  background: #e6e6e6;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  outline: 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 5px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-body .body{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding-top: 16px;
}

.modal-body .body .potential-contacts-outter{
  width: 100%;
  max-height: 550px;
}

.modal-body .body .potential-contacts-outter > div{
  border-bottom: 1px solid var(--theme-grey-text3-color);
  padding-bottom: 16px;
}

.modal-body .body .potential-contacts-outter div .disable{
  text-decoration: line-through;
}

.modal-body .body .potential-contacts-outter div .enable{
  font-weight: 600;
}

.modal-body .body .potential-contacts-outter .header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body .body .potential-contacts-outter .header h4{
  border-radius: 5px;
  padding: 3px;
}

.modal-body .body .potential-contacts-outter .header.red h4{
  background: var(--theme-negative-color);
}

.modal-body .body .potential-contacts-outter .header.orange h4{
  background: var(--theme-playful-color3);
}

.modal-body .body .potential-contacts-outter .header.green h4{
  background: var(--theme-playful-color4);
}

.modal-body .body .potential-contacts-outter .header .bubble-outter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-body .body .potential-contacts-outter .header .bubble{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  position: relative;
}

.modal-body .body .potential-contacts-outter .header .border{
  position: absolute;
  border: 2px solid;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
}

.modal-body .body .potential-contacts-outter .header .bubble.red .border{
  border-color: var(--theme-negative-color);
}
.modal-body .body .potential-contacts-outter .header .bubble.red{
  background: var(--theme-negative-color);
}
.modal-body .body .potential-contacts-outter .header .bubble.orange{
  background: var(--theme-playful-color3);
}
.modal-body .body .potential-contacts-outter .header .bubble.orange .border{
  border-color: var(--theme-playful-color3);
}
.modal-body .body .potential-contacts-outter .header .bubble.green{
  background: var(--theme-playful-color4);
}
.modal-body .body .potential-contacts-outter .header .bubble.green .border{
  border-color: var(--theme-playful-color4);
}

.modal-body .body .potential-contacts-outter > div:last-child{
  border-bottom: 0;
}

.modal-body .body .slider-box{
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.modal-body .body .text-area{
  height: 200px;
  width: 100%;
  padding-bottom: 16px;
}

.modal-body .body .text-area > div, .modal-body .body .text-area > div textarea{
  height: 100%;
}

.modal-body .body .slider-box .indication{
  font-size: 12px;
  margin-left: 12px;
}

.modal-body .body .slider-box .MuiSlider-root{
  margin-left: 24px;
}

.modal-body .body .slider-box .MuiSlider-root{
  width: 50%;
}

.modal-body .body::-webkit-scrollbar {
  width: 12px;
}

.modal-body .body .no-content{
  text-align: center;
}

.modal-body .body .no-content span{
  color: black;
  opacity: .8;
}

.modal-body .footer{
  display: flex;
  justify-content: flex-end;
}

.modal-body .footer button{
  margin-left: 12px;
}

