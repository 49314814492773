.home-page{
  height: 100%;
  width: 100%;

  .content-wrapper{
    position: relative;
    .cmd-wrapper{
      min-width: 350px;
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;

      .cmd-inner{
        display: flex;
        flex-direction: column;

        .btn-wrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          .box{
            margin: 16px 0;
          }
          .button{
            margin-left: 0;
            margin-top: 24px;
          }
          .icon{
            font-size: 12px;
          }

          .row{
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }
}
