.menu-item{
  vertical-align: baseline;
  position: relative;
  margin-top: 16px;
  cursor: pointer;

  &.active{
    background: var(--theme-primary-lighter-color);
  }

  .icon{
    float: left;
    color: var(--theme-white-color);
    font-size: 17px;
  }

  .text-wrapper{
    position: absolute;
    width: 100%;
    text-align: left;
    left: 90px;
  }

  button{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    color: var(--theme-unactive-text-color);
    background-color: transparent;
    width: 160px;
    height: 45px;
  }
}

.menu-item:hover{
  background-color: var(--theme-primary-lighter-color);
}
