.sidebar{
  width: 220px;
  height: 100%;
  background-color: var(--theme-primary-color);
  position: fixed;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .main-button{
    min-height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: white;
      font-weight: 600;
      font-size: 22px;
    }
  }

  .logout-button{
    margin-bottom: 10px;
  }
}
