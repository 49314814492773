.about-page{
  height: 100%;
  max-width: 70%;
  div {
    line-height: 25px;
  }

  .developed{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img{
      height: 50px;
    }
    div{
      display: flex;
      flex-direction: column;
      margin-left: 16px;
    }
  }
}
