.login-page{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;

  #login-page-wrapper {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .login-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 20px;

    .logo-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 56px;

      .logo{
        width: 100px;
      }
      span{

      }
    }

    h1 {
      font-size: 20px;
      margin-bottom: 35px;
      color: var(--theme-secondary-color);
    }

    .login-form {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      .button-loading-outter{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 55px;
        .loading-icon{
          color: var(--theme-secondary-color)
        }
      }

      .text-field{
        margin-top: 20px;
      }

      .button{
        margin-top: 36px;
        flex: 1;
      }
    }

    .register-link{
      color: var(--theme-secondary-color);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }

    .error-txt{
      font-size: 14px;
      color: var(--theme-negative-color);
      margin-top: 18px;
    }
  }


  .corner {
    position: absolute;
    background: var(--theme-secondary-color);
    transform: rotate(45deg);
    width: 250px;
    height: 375px;
    box-shadow: 0px 0px  20px rgba(100,100,100,0.5);

    &.right {
      top: -250px;
      right: -170px;
    }

    &.left {
      bottom: -250px;
      left: -170px;
    }
  }

  .version-number {
    position: absolute;
    bottom: 30px;
    right: 30px;
    font-size: 13px;
    color: #686868;
  }
}
