.button{
  cursor: pointer;
  vertical-align: baseline;
  position: relative;
  margin: 8px;

  .icon{
    float: left;
    color: var(--theme-white-color);
    opacity: .5;
    font-size: 17px;
  }

  .text-wrapper{
    left: 0;
    width: 100%;
    text-align: center;
  }

  button{
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    color: var(--theme-white-color);
    background-color: var(--theme-secondary-color);
    width: 160px;
    height: 45px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &.disabled button{
    cursor: initial;
    background-color: var(--theme-secondary-inactive-color);
  }

  button:active{
    background-color: var(--theme-secondary-lighter-color);
  }

  &.disabled button:active{
    background-color: var(--theme-secondary-inactive-color);
  }
}
