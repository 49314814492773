html{
  overflow: hidden;
  overflow-y: scroll;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-body{
  min-height: 200px;
  max-height: 550px;
  width: 500px;
  background: #e6e6e6;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  outline: 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 5px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-body .body{
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding-top: 16px;
}

.modal-body .body .potential-contacts-outter{
  width: 100%;
  max-height: 550px;
}

.modal-body .body .potential-contacts-outter > div{
  border-bottom: 1px solid var(--theme-grey-text3-color);
  padding-bottom: 16px;
}

.modal-body .body .potential-contacts-outter div .disable{
  text-decoration: line-through;
}

.modal-body .body .potential-contacts-outter div .enable{
  font-weight: 600;
}

.modal-body .body .potential-contacts-outter .header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body .body .potential-contacts-outter .header h4{
  border-radius: 5px;
  padding: 3px;
}

.modal-body .body .potential-contacts-outter .header.red h4{
  background: var(--theme-negative-color);
}

.modal-body .body .potential-contacts-outter .header.orange h4{
  background: var(--theme-playful-color3);
}

.modal-body .body .potential-contacts-outter .header.green h4{
  background: var(--theme-playful-color4);
}

.modal-body .body .potential-contacts-outter .header .bubble-outter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-body .body .potential-contacts-outter .header .bubble{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  position: relative;
}

.modal-body .body .potential-contacts-outter .header .border{
  position: absolute;
  border: 2px solid;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
}

.modal-body .body .potential-contacts-outter .header .bubble.red .border{
  border-color: var(--theme-negative-color);
}
.modal-body .body .potential-contacts-outter .header .bubble.red{
  background: var(--theme-negative-color);
}
.modal-body .body .potential-contacts-outter .header .bubble.orange{
  background: var(--theme-playful-color3);
}
.modal-body .body .potential-contacts-outter .header .bubble.orange .border{
  border-color: var(--theme-playful-color3);
}
.modal-body .body .potential-contacts-outter .header .bubble.green{
  background: var(--theme-playful-color4);
}
.modal-body .body .potential-contacts-outter .header .bubble.green .border{
  border-color: var(--theme-playful-color4);
}

.modal-body .body .potential-contacts-outter > div:last-child{
  border-bottom: 0;
}

.modal-body .body .slider-box{
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.modal-body .body .text-area{
  height: 200px;
  width: 100%;
  padding-bottom: 16px;
}

.modal-body .body .text-area > div, .modal-body .body .text-area > div textarea{
  height: 100%;
}

.modal-body .body .slider-box .indication{
  font-size: 12px;
  margin-left: 12px;
}

.modal-body .body .slider-box .MuiSlider-root{
  margin-left: 24px;
}

.modal-body .body .slider-box .MuiSlider-root{
  width: 50%;
}

.modal-body .body::-webkit-scrollbar {
  width: 12px;
}

.modal-body .body .no-content{
  text-align: center;
}

.modal-body .body .no-content span{
  color: black;
  opacity: .8;
}

.modal-body .footer{
  display: flex;
  justify-content: flex-end;
}

.modal-body .footer button{
  margin-left: 12px;
}


:root {
  --theme-primary-color: #1C2237;
  --theme-primary-lighter-color: #212943;
  --theme-secondary-color: #295589;
  --theme-secondary-lighter-color: #3c5f8a;
  --theme-secondary-inactive-color: rgba(60, 95, 138, 0.36);
  --theme-grey-background-color: #EFF3FC;
  --theme-grey-icons-color: #7685B6;
  --theme-grey-text1-color: #ABB9C7;
  --theme-grey-text2-color: #C3CBD3;
  --theme-grey-text3-color: #7689B6;
  /*opacity 73*/
  --theme-grey-text4-color: #4666AE;
  /*opacity 67*/
  --theme-grey-border-color: #8494A56b;
  /*opacity 67*/
  --theme-white-color: #FFFFFF;
  --theme-green-color: #24A71E;
  --theme-red-color: #FC297D;
  --theme-unactive-text-color: #7685B6;
  --theme-playful-color1: #FC297D;
  --theme-playful-color2: #FA6B41;
  --theme-playful-color3: #FECB2F;
  --theme-playful-color4: #74D20E;
  --theme-playful-color5: #00F4FF;
  --theme-playful-color6: #8e44ad;
  --theme-negative-color: #f03434;
  --theme-positive-color: #2ecc71; }

.app .app-wrapper {
  display: flex;
  flex-direction: row; }
  .app .app-wrapper .screen-wrapper {
    height: 100%;
    width: 100%;
    padding-left: 220px;
    padding-top: 72px; }
  .app .app-wrapper .page-wrapper {
    padding: 8px 16px; }

.app header {
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  position: fixed;
  z-index: 3;
  background: white;
  right: 0;
  top: 0;
  left: 220px;
  min-height: 72px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px; }
  .app header span:first-of-type {
    color: #ABB9C7;
    color: var(--theme-grey-text1-color);
    font-weight: 600;
    font-size: 22px; }

.app .loading-mask, .app .loading-mask-outter {
  overflow: hidden;
  position: absolute;
  height: calc(100vh - 72px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; }

.app .loading-mask-outter > * {
  z-index: 100; }

.app .loading-mask-outter .card-progress {
  height: 150px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; }
  .app .loading-mask-outter .card-progress h3 {
    margin-top: 0; }
  .app .loading-mask-outter .card-progress span {
    margin-bottom: 16px; }
  .app .loading-mask-outter .card-progress .progress {
    width: 100%; }

.app .loading-mask {
  background-color: #EFF3FC;
  background-color: var(--theme-grey-background-color);
  opacity: .7; }
  .app .loading-mask > svg {
    margin-left: -220px; }

.app .card-progress {
  -webkit-transform: translateX(-220px);
          transform: translateX(-220px); }

.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%; }
  .login-page #login-page-wrapper {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative; }
  .login-page .login-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 20px; }
    .login-page .login-content-wrapper .logo-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 56px; }
      .login-page .login-content-wrapper .logo-wrapper .logo {
        width: 100px; }
    .login-page .login-content-wrapper h1 {
      font-size: 20px;
      margin-bottom: 35px;
      color: var(--theme-secondary-color); }
    .login-page .login-content-wrapper .login-form {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column; }
      .login-page .login-content-wrapper .login-form .button-loading-outter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 55px; }
        .login-page .login-content-wrapper .login-form .button-loading-outter .loading-icon {
          color: var(--theme-secondary-color); }
      .login-page .login-content-wrapper .login-form .text-field {
        margin-top: 20px; }
      .login-page .login-content-wrapper .login-form .button {
        margin-top: 36px;
        flex: 1 1; }
    .login-page .login-content-wrapper .register-link {
      color: var(--theme-secondary-color);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer; }
      .login-page .login-content-wrapper .register-link:hover {
        text-decoration: underline; }
    .login-page .login-content-wrapper .error-txt {
      font-size: 14px;
      color: var(--theme-negative-color);
      margin-top: 18px; }
  .login-page .corner {
    position: absolute;
    background: var(--theme-secondary-color);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 250px;
    height: 375px;
    box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.5); }
    .login-page .corner.right {
      top: -250px;
      right: -170px; }
    .login-page .corner.left {
      bottom: -250px;
      left: -170px; }
  .login-page .version-number {
    position: absolute;
    bottom: 30px;
    right: 30px;
    font-size: 13px;
    color: #686868; }

.home-page {
  height: 100%;
  width: 100%; }
  .home-page .content-wrapper {
    position: relative; }
    .home-page .content-wrapper .cmd-wrapper {
      min-width: 350px;
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column; }
      .home-page .content-wrapper .cmd-wrapper .cmd-inner {
        display: flex;
        flex-direction: column; }
        .home-page .content-wrapper .cmd-wrapper .cmd-inner .btn-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between; }
          .home-page .content-wrapper .cmd-wrapper .cmd-inner .btn-wrapper .box {
            margin: 16px 0; }
          .home-page .content-wrapper .cmd-wrapper .cmd-inner .btn-wrapper .button {
            margin-left: 0;
            margin-top: 24px; }
          .home-page .content-wrapper .cmd-wrapper .cmd-inner .btn-wrapper .icon {
            font-size: 12px; }
          .home-page .content-wrapper .cmd-wrapper .cmd-inner .btn-wrapper .row {
            display: flex;
            flex-direction: row;
            align-items: center; }

.button {
  cursor: pointer;
  vertical-align: baseline;
  position: relative;
  margin: 8px; }
  .button .icon {
    float: left;
    color: var(--theme-white-color);
    opacity: .5;
    font-size: 17px; }
  .button .text-wrapper {
    left: 0;
    width: 100%;
    text-align: center; }
  .button button {
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    color: var(--theme-white-color);
    background-color: var(--theme-secondary-color);
    width: 160px;
    height: 45px;
    border-radius: 4px; }
  .button.disabled button {
    cursor: auto;
    cursor: initial;
    background-color: var(--theme-secondary-inactive-color); }
  .button button:active {
    background-color: var(--theme-secondary-lighter-color); }
  .button.disabled button:active {
    background-color: var(--theme-secondary-inactive-color); }

.card {
  background-color: var(--theme-white-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 16px;
  margin: 8px; }

.history-page {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative; }
  .history-page .no-content {
    text-align: center;
    margin-top: 56px;
    font-size: 24px;
    color: var(--theme-grey-text3-color); }
  .history-page .header {
    height: 72px;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center; }
    .history-page .header .date-wrapper > * {
      margin-left: 16px; }
  .history-page .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
  .history-page .card-wrapper {
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column; }
    .history-page .card-wrapper .title {
      font-weight: 600;
      color: var(--theme-grey-icons-color); }
    .history-page .card-wrapper .content {
      font-weight: 300;
      color: var(--theme-grey-text1-color); }
    .history-page .card-wrapper .positive, .history-page .card-wrapper .negative {
      border: 1px solid;
      padding: 5px 25px;
      border-radius: 3px; }
    .history-page .card-wrapper .positive {
      color: var(--theme-positive-color);
      border-color: var(--theme-positive-color); }
    .history-page .card-wrapper .negative {
      color: var(--theme-negative-color);
      border-color: var(--theme-negative-color); }

.collapse {
  box-shadow: none !important;
  margin: 0 !important; }
  .collapse .no-content-small {
    padding-left: 16px;
    font-size: 16px;
    color: var(--theme-grey-text3-color); }
  .collapse:hover .panel-summary {
    background: var(--theme-grey-border-color); }
  .collapse .panel-summary {
    border-radius: 5px;
    border: none;
    color: var(--theme-primary-color);
    font-weight: 600;
    font-size: 16px;
    min-height: 56px !important;
    padding: 0; }
    .collapse .panel-summary > div {
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-right: 16px;
      padding-left: 16px; }
      .collapse .panel-summary > div .name-box {
        flex: 1 1;
        padding-right: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .collapse .panel-summary > div .name-box span:first-of-type {
          margin-right: 16px; }
      .collapse .panel-summary > div .collapse-box {
        text-align: right; }
        .collapse .panel-summary > div .collapse-box .icon-collapse {
          margin-left: 16px; }
      .collapse .panel-summary > div .icon {
        color: var(--theme-grey-text3-color);
        transition: all .4s; }
  .collapse .panel-details {
    padding: 16px 0; }
  .collapse.Mui-expanded .panel-summary .icon-collapse {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.table-component {
  box-shadow: none !important; }
  .table-component .owners-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.about-page {
  height: 100%;
  max-width: 70%; }
  .about-page div {
    line-height: 25px; }
  .about-page .developed {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .about-page .developed img {
      height: 50px; }
    .about-page .developed div {
      display: flex;
      flex-direction: column;
      margin-left: 16px; }

.sidebar {
  width: 220px;
  height: 100%;
  background-color: var(--theme-primary-color);
  position: fixed;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center; }
  .sidebar .main-button {
    min-height: 72px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .sidebar .main-button span {
      color: white;
      font-weight: 600;
      font-size: 22px; }
  .sidebar .logout-button {
    margin-bottom: 10px; }

.list {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  position: relative;
  color: var(--theme-unactive-text-color); }

.menu-item {
  vertical-align: baseline;
  position: relative;
  margin-top: 16px;
  cursor: pointer; }
  .menu-item.active {
    background: var(--theme-primary-lighter-color); }
  .menu-item .icon {
    float: left;
    color: var(--theme-white-color);
    font-size: 17px; }
  .menu-item .text-wrapper {
    position: absolute;
    width: 100%;
    text-align: left;
    left: 90px; }
  .menu-item button {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    color: var(--theme-unactive-text-color);
    background-color: transparent;
    width: 160px;
    height: 45px; }

.menu-item:hover {
  background-color: var(--theme-primary-lighter-color); }

