:root {
  --theme-primary-color: #1C2237;
  --theme-primary-lighter-color: #212943;
  --theme-secondary-color: #295589;
  --theme-secondary-lighter-color: #3c5f8a;
  --theme-secondary-inactive-color: rgba(60, 95, 138, 0.36);
  --theme-grey-background-color: #EFF3FC;
  --theme-grey-icons-color: #7685B6;
  --theme-grey-text1-color: #ABB9C7;
  --theme-grey-text2-color: #C3CBD3;
  --theme-grey-text3-color: #7689B6;/*opacity 73*/
  --theme-grey-text4-color: #4666AE;/*opacity 67*/
  --theme-grey-border-color: #8494A56b;/*opacity 67*/
  --theme-white-color: #FFFFFF;
  --theme-green-color: #24A71E;
  --theme-red-color: #FC297D;
  --theme-unactive-text-color: #7685B6;
  --theme-playful-color1: #FC297D;
  --theme-playful-color2: #FA6B41;
  --theme-playful-color3: #FECB2F;
  --theme-playful-color4: #74D20E;
  --theme-playful-color5: #00F4FF;
  --theme-playful-color6: #8e44ad;
  --theme-negative-color: #f03434;
  --theme-positive-color: #2ecc71;
}

.app {

  .app-wrapper{
    display: flex;
    flex-direction: row;
    .screen-wrapper{
      height: 100%;
      width: 100%;
      padding-left: 220px;
      padding-top: 72px;
    }

    .page-wrapper{
      padding: 8px 16px;
    }
  }
  header{
    box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    position: fixed;
    z-index: 3;
    background: white;
    right: 0;
    top: 0;
    left: 220px;
    min-height: 72px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    span:first-of-type{
      color: var(--theme-grey-text1-color);
      font-weight: 600;
      font-size: 22px;
    }
  }

  .loading-time-mask{

  }

  .loading-mask, .loading-mask-outter{
    overflow: hidden;
    position: absolute;
    height: calc(100vh - 72px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .loading-mask-outter{
    >*{
      z-index: 100;
    }
    .card-progress{
      height: 150px;
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      h3{
        margin-top: 0;
      }
      span{
        margin-bottom: 16px;
      }
      .progress{
        width: 100%;
      }
    }
  }

  .loading-mask{
    background-color: var(--theme-grey-background-color);
    opacity: .7;
    > svg{
      margin-left: -220px;
    }
  }

  .card-progress{
      transform: translateX(-220px);
  }
}
