.collapse {
  box-shadow: none !important;
  margin: 0 !important;

  .no-content-small {
    padding-left: 16px;
    font-size: 16px;
    color: var(--theme-grey-text3-color);
  }

  &:hover .panel-summary {
    background: var(--theme-grey-border-color);
  }

  .panel-summary {
    border-radius: 5px;
    border: none;
    color: var(--theme-primary-color);
    font-weight: 600;
    font-size: 16px;
    min-height: 56px !important;
    padding: 0;

    > div {
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-right: 16px;
      padding-left: 16px;

      .name-box {
        flex: 1;
        padding-right: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        span:first-of-type{
          margin-right: 16px;
        }
      }

      .collapse-box {
        text-align: right;
        .icon-collapse {
          margin-left: 16px;
        }
      }

      .icon {

        color: var(--theme-grey-text3-color);
        transition: all .4s;
      }
    }
  }

  .panel-details {
    padding: 16px 0;
  }

  &.Mui-expanded {
    .panel-summary {
      .icon-collapse {
        transform: rotate(180deg);
      }
    }
  }
}
