.table-component{
  box-shadow: none!important;

  .owners-cell{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
