.history-page{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .no-content{
    text-align: center;
    margin-top: 56px;
    font-size: 24px;
    color: var(--theme-grey-text3-color);
  }

  .header{
    height: 72px;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;

    .date-wrapper{
      >* {
        margin-left: 16px;
      }
    }
  }

  .content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .card-wrapper{
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    .title{
      font-weight: 600;
      color: var(--theme-grey-icons-color);
    }
    .content{
      font-weight: 300;
      color: var(--theme-grey-text1-color);
    }
    .positive, .negative{
      border: 1px solid;
      padding: 5px 25px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
    .positive{
      color: var(--theme-positive-color);
      border-color: var(--theme-positive-color);
    }
    .negative{
      color: var(--theme-negative-color);
      border-color: var(--theme-negative-color);
    }
  }
}
